.admin-dashboard {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.users-table {
  width: 100%;
  /* Full width of the dashboard container */
  margin: auto;
  max-height: 48vh;
  /* Centering the table */
  border-collapse: collapse;
  overflow: auto;
}

.users-table .sticky-row {
  position: sticky;
  bottom: -2px;
  background-color: #1E1E1E;
  z-index: 30;

  /* Set a solid background color */
  /* Ensure the row's background matches the table */
  /* Make sure it stacks above other content */
}

.users-table table {
  width: 100%;
  /* Ensures the table uses the full width of its container */
}

.users-table th:last-child,
.users-table td:last-child {
  width: 12vw
    /* Same as the dashboard background */
}

.users-table th,
.users-table td {
  text-align: center;
  padding: 12px;
  font-weight: bold;
  border-bottom: 1px solid #333333;
}

.users-table th {
  background-color: #424242;
  /* Darker header */
  color: #E0E0E0;
}

.users-table tr:hover {
  background-color: #363636;
  /* Dark hover state */
}


.users-table input {
  width: auto
}

.add-user-button {
  background-color: #891b1b;
  /* Green background */
  color: #ffffff;
  border: none;
  padding: 10px;
  font-weight: bold;
  border-radius: 0;
  /* Remove border-radius to align with the table style */
  cursor: pointer;
  width: 100%;
  /* Full width to align with the table */
  box-sizing: border-box;
  /* Ensures padding does not affect the width */
  transition: background-color 0.3s, transform 0.2s;
}

.add-user-button:hover {
  /* Darker green on hover */
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-button,
.confirm-button,
.cancel-button,
.delete-button {
  width: 40%;
  /* or any specific width */
  border: none;
  cursor: pointer;
  color: #ffffff;
}

.edit-button {
  background-color: #07294e;
  margin-right: 10px;
}

.edit-button:hover {
  background-color: #0d3b6c;
}

.confirm-button {
  background-color: #19632a;

  margin-right: 10px;
}

.confirm-button:hover {
  background-color: #1e7b33;
}

.cancel-button,
.delete-button {
  background-color: #891b1b;
}

.cancel-button:hover,
.delete-button:hover {
  background-color: #a41f1f;
}

.users-table input {
  z-index: 4;
  height: 2vw;
  font-weight: bold;
  text-align: center;
  border: 1px solid #757575;
  background-color: #333333;
  color: #e0e0e0;
  padding: 10px;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.users-table input:hover {
  border-color: #ff616d;
}

.users-table input:focus {
  border-color: #ffc107;
  outline: none;
  box-shadow: 0 0 0 2px #ff616d;
}

td .email-input {
  width: 20vw;
  /* Adjust the width as needed */
  /* Ensures a minimum width regardless of container size */
}

.projects-div2 {
  margin-top: 30px;
  /* Increased space from the header */
  padding: 20px;
  /* Inner spacing */
  background-color: #2b2828;
  /* Light background for emphasis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  border-radius: 8px;
  /* Softened edges */
}

.footer-admin {
  background-color: #2b2828;
  color: #E0E0E0;
  text-align: center;
  margin-top: 10px;
  border-radius: 0 0 8px 8px;
  padding: 10px;
  font-size: 0.8em;
}

.footer-admin button {
  background-color: rgb(34, 31, 31);
  width: 100%
}


.users-container td {
  padding: 12px 15px;
  /* Uniform padding */
  background-color: #1E1E1E;
  /* Slightly lighter than table wrapper for contrast */
  /* Lighter text for readability */
  border-bottom: 1px solid #333333;
  /* Subtle separation */
  font-size: 0.9em;
  /* Optimal size for content */
}

.users-container tr {
  transition: background-color 0.3s;
  /* Smooth transition for hover */
}




.users-container th:first-child,
.users-container td:first-child {
  border-top-left-radius: 8px;
  /* Smoothed corners */
  border-bottom-left-radius: 8px;
}

.users-container th:last-child,
.users-container td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.users-container table {
  width: 100%;
}