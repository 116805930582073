@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  /* Dark background */
  color: #e0e0e0;
  /* Lighter text for better readability */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  /* Softer shadow for dark mode */
  height: 100vh;
  box-sizing: border-box;
}

.App,
.left-panel,
.right-panel,
.middle-panel,
.keyword-list,
.settings,
.rules {
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* Adds a subtle border */
}

.App {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  background: linear-gradient(135deg, #0A0F0D 0%, #3C3B3F 100%);
  /* Enhanced gradient transition from dark red to black for a smoother visual effect */
  color: #e0e0e0;
  /* Lighter text color for better readability against the dark background */
  padding: 30px;
  /* Increased padding for a more spacious layout */
  border-radius: 12px;
  /* Slightly larger border-radius for a softer edge appearance */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  /* Deeper shadow for a more pronounced, yet smooth depth effect */
  height: 97vh;
  justify-content: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  /* Smooth transition effect for any changes */
}



.automated-sourcing-tool {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  background: linear-gradient(135deg, #D32F2F, #3E2723);
  /* A sharp, modern gradient from a vivid red to a deep, dark brownish-black */
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  /* Pure white text for maximum contrast */
  padding: 20px 40px;
  margin-bottom: 0;
  text-align: center;
  /* Removing border-radius for a sharper, more modern look */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  /* A deeper shadow for a floating effect */
  text-shadow: none;
  /* Removing text shadow for a cleaner, more flat design */
  position: relative;
  overflow: hidden;
  /* Setup for potential animated elements */
}

.header p {
  font-size: 1.2rem;
  /* Slightly larger for readability */
  color: #E0E0E0;
  /* Lighter grey for contrast, soft on the eyes */
  font-weight: 400;
  /* Regular weight for cleanliness */
  letter-spacing: 0.5px;
  /* Slight spacing to enhance readability */
  opacity: 0.9;
  /* Adjusted for better visibility */
  max-width: 80%;
  /* Limiting width for alignment */
  margin: 0 auto;
  /* Centering */
  line-height: 1.5;
  /* Line spacing for readability */
}

.header h1 {
  margin: 0;
  font-size: 3rem;
  /* Size for impact */
  font-weight: 900;
  /* Bold weight for presence */
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.599));
  /* Transitioning from transparent to semi-transparent black */
  -webkit-background-clip: text;
  color: transparent;
  /* Applying gradient to text */
  -webkit-text-stroke: 2px black;
  /* Black border for visibility */
  letter-spacing: -2px;
  /* Compact letter spacing */
  margin-bottom: 0.5rem;
  animation: textGlow 30s linear infinite;
  /* Infinite animation for dynamic text appearance */
}

.header .titleLetters {
  text-shadow: 1px 1px 2px #000000;
  ;
  /* Positioning for absolute children */
}

@keyframes textGlow {

  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.4);
    opacity: 0.8;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.header {
  /* Ensure your header has a defined height or min-height */
  min-height: 65px;
  /* Example height, adjust based on your header */
}

.header::after {
  content: '';
  position: absolute;
  top: -50%;

  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, transparent, #8B0000, transparent);
  /* A subtle radial gradient for a dynamic visual effect */
  animation: rotate 15s linear infinite;
  /* Smooth, continuous rotation for a captivating look */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-content {
  flex-grow: 1;
  display: flex;
  margin-top: 20px;
  height: 48vh;
  flex-direction: row;
  gap: 20px;
  /* Horizontal layout by default */

}

.left-panel,
.right-panel,
.middle-panel {
  flex-grow: 1;
  /* Minimum height based on viewport height */
  width: 33%;
  /* Set a fixed width */

  padding: 20px;
  background-color: #424242;
  /* Dark grey for panels */
  color: #e0e0e0;
  /* Light text for readability */
  border-radius: 8px;
  margin-right: 10px;
  overflow: auto;
  /* Allows content to scroll if it overflows */

  transition: transform 0.2s;
  /* Smooth transition for zoom effect */
}

.left-panel:hover,
.right-panel:hover,
.middle-panel:hover {
  transform: scale(1.02);
  /* Slightly zoom in when hovered */
  box-shadow: 0 0 8px 2px #52070d;
  /* Add red glow around */
}

.right-panel {
  margin-right: 0;
}

.charge-code,
.client-name,
.request-date,
.category {
  flex-grow: 1;
  margin-bottom: 20px;
}

.charge-code input {
  width: 10vw !important;
  /* Full width for input */

}

.request-date input {
  width: 10vw !important;
  /* Full width for input */
}

.keyword-list {
  height: 35vh;
  /* Ensure scrollbar is visible */
}

.keyword-list,
.settings,
.rules {
  background-color: #333333;
  /* Dark grey for sections */
  color: #e0e0e0;
  /* Light text for readability */
  padding: 10px;
  border-radius: 8px;

}

.rules {
  height: 42vh;

  /* Ensure scrollbar is visible */

}

.keyword,
.excluded-website {
  background-color: #505050;
  /* Medium dark grey for items */
  color: #e0e0e0;
  /* Light text for readability */
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keyword button,
.excluded-website button {
  margin-left: 10px;
  background-color: #e53935;
  /* Red for buttons */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.footer button {
  text-align: center;
  margin-top: 20px;
  background-color: #424242;
  /* Dark grey, already used for panels */
  /* Deep blue */
  border-radius: 4px;


  width: 100%;
  color: #e0e0e0;
  /* Light text for readability */
}


.start-sourcing-button:hover {
  background-color: #263C5A;
  /* Darker shade of blue for hover state */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(229, 57, 53, 0.7);
  }

  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(229, 57, 53, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(229, 57, 53, 0);
  }
}

.pulseAnimation {
  animation: pulse 2s infinite;
}

button {
  background-color: #e53935;
  /* Yellow for buttons */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.3s;
}

button:hover,
input[type="text"]:hover,
.date-input-style:hover,
select:hover {
  border-color: #ff8a65;
  /* Changes to a softer red on hover */
  background-color: #301b1c;
  /* Ensures consistency in button hover state */
}

input[type="text"],
select {
  z-index: 4;
  /* High z-index to ensure it's on top */
  height: 2vw;
  font-weight: bold;
  text-align: center;
  border: 1px solid #757575;
  /* softer border for inputs */
  background-color: #333333 !important;
  /* dark grey background for inputs */
  color: #e0e0e0;
  /* light text for contrast */
  padding: 10px;
  border-radius: 4px;
  width: 20vw;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
select:focus {
  border-color: #ffc107;
  /* Yellow border on focus */
}

label {
  display: block;
  margin-bottom: 5px;
  margin-top: 10px;
  font-weight: bold;
  color: #e0e0e0;
}

.date {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #e0e0e0;
}

input[type="text"]:hover,
select:hover {
  border-color: #ff616d;
  /* Change to a red border on hover for better interaction feedback */
}

button:focus,
input[type="text"]:focus,
select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #ff616d;
  /* Adding a soft glow effect on focus with a red hue */
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .left-panel,
  .right-panel {
    margin-right: 0;
    margin-bottom: 20px;
    /* Add space between panels when stacked */
  }

  input[type="text"],
  select {
    width: 100%;
    /* Full width for smaller screens */
  }
}

.project-info {
  display: flex;
  flex-wrap: wrap;
  min-height: 8vh;
  /* Allows child elements to wrap onto the next line */
  /* Spreads out the child elements evenly */
  background-color: #424242;
  padding: 1vw;
  margin-top: 0;
}

.project-info label

/* Adjust as needed */
  {
  flex-grow: 1;
  font-size: 0.8rem;
  margin-right: 10px;
  /* Adjust as needed */
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
    background-color: rgb(5, 205, 5);

  }

  to {
    opacity: 1;
    transform: translateY(0);
    background-color: initial;

  }
}

.keyword-animate {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
    background-color: initial;
  }

  to {
    opacity: 0;
    transform: translateY(-20px);
    background-color: red;
  }
}

@keyframes fadeOut2 {
  from {
    opacity: 1;
    transform: translateX(0px);
    background-color: initial;
  }

  to {
    opacity: 0;
    transform: translateX(-20px);
    background-color: black;
  }
}


.keyword-remove-animate {
  animation: fadeOut2 0.5s ease-out forwards;
  /* Use forwards to keep the final state after the animation */
}

.flagSelector {
  margin-top: 1vw;
  border-radius: 4px;
}

.add-keyword {
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}

.add-keyword input {
  flex-grow: 1;
  margin-right: 10px;
  /* Adjust as needed */
}

.add-keyword button {
  width: 40px;
  /* Adjust width as needed */
  height: 40px;
  /* Adjust height as needed */
  border-radius: 50%;
  /* Makes the button round */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  /* Adjust font size as needed */
}



.project-info.fade-out {
  animation: toggleSelections 3s forwards;
}

button,
.keyword button,
.excluded-website button {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Adds a subtle shadow for depth */
}

button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Enhances shadow on hover for a "lifted" effect */
}

/* Enhance the sliding menu with a border for better definition */
.sliding-menu {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

/* Adjust the main content shadow for consistency and subtlety */
.main-content,
.left-panel,
.right-panel,
.middle-panel {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Ensure all interactive elements have a consistent focus state */
button:focus,
input[type="text"]:focus,
select:focus {
  box-shadow: 0 0 0 3px #ff8a65;
  /* Soft glow effect with a softer hue */
}

.date-input-style {
  font-weight: bold;
  text-align: center;
  border: 1px solid #757575;
  /* softer border for inputs */
  background-color: #333333;
  /* dark grey background for inputs */
  color: #e0e0e0;
  /* light text for contrast */
  padding: 10px;
  border-radius: 4px;
  width: 20vw;
  height: 2vw;
  transition: border-color 0.3s;
}



.search-depth,
.result-pages,
.search-type {
  margin-bottom: 20px;
}

.search-depth label,
.result-pages label,
.search-type label {
  margin-bottom: 5px;
  font-weight: bold;
}

.excluded-website {
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  margin: 20px auto;
  /* Centering margin */
  text-align: center;
  transition: transform 0.5s, background-color 0.5s;
  display: block;
  /* Ensure elements are block-level for margin auto to work */
  border: 1px solid #ccc;
  /* Added border like other elements */
  border-radius: 8px;
  /* Increased border radius for more rounded corners */
}

.excluded-website.toggled-on {
  background-color: rgba(34, 139, 34, 0.8);
  /* Darker, more elegant green with opacity */
  transition: transform 0.6s, background-color 0.6s;
}

.excluded-website.toggled-off {
  background-color: rgba(178, 34, 34, 0.8);
  /* Darker, more elegant red with opacity */
  opacity: 0.5;
  /* Lower opacity when toggled off */
  transition: transform 0.6s, background-color 0.6s, opacity 0.6s;
}


.sourcingButton {

  font-weight: bold;
  font-family: 'Montserrat', sans-serif;

}



.recap-info h2,
.recap-info p {
  margin: 5px 0;
  /* Adjust font size to ensure content fits */
}

.recap-info span {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  /* Space between countries/languages */
}

/* Ensure flags are properly sized */
.recap-info .CountryFlag {
  width: 20px;
  /* Adjust based on your preference */
  height: auto;
  margin-right: 5px;
  /* Space between flag and text */
}

.sourcing-results {

  flex-direction: column;
  background-color: #424242;
  flex-grow: 1;
  height: 15vw;
  /* Set a max-height or height to enable scrolling */
  overflow: hidden;
  /* Ensure the height matches the other divs */
  color: #e0e0e0;
  /* Assuming this is the text color used */
  /* Adjust based on the padding of the central divs */
  border-radius: 8px;
  /* Assuming this matches the border radius of the central divs */
  margin-top: 15px;
  transition: max-height 0.3s ease-in-out;
  /* Smooth transition for shrinking/expanding */

  /* Adjust based on the layout */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  /* Assuming this matches the shadow of the central divs */
}


/* Animation for the divs disappearing */
@keyframes toggleSelections {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
}

/* Animation for fading in as the opposite of toggleSelections */
@keyframes startSourcingFadeIn {
  0% {
    opacity: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: toggleSelections 3s forwards;
}

.fade-in {
  animation: startSourcingFadeIn 3s forwards;
}


/* Apply the animations to the divs */


/* Optional: Add a slight transition effect to the recap-info-container itself for a smoother expansion */
.recap-info-container {
  max-height: 10vh;
  transition: max-height 0.3s ease-in-out, background-color 0.3s ease-in-out;
  /* Adjust based on the height of your first line */
  background-color: #424242;
  border-bottom: 1px solid rgb(81, 6, 6);
  /* Added subtle border to the bottom */
  /* Added red glow */
}

.recap-info-container:hover {
  height: auto;
  max-height: 100vw;
  /* Adjust this value based on the actual height needed */
}

.recap-info:hover {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* Updated to create three equal columns */
  gap: 20px;
  color: #e0e0e0;
}

.recap-info>div {
  background-color: #333333;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  /* Ensures content fits within each div */
}

.recap-info>div:hover {
  /* Increase max-height on hover */
  /* Other hover effects */
  /* Slight lift effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  /* Enhanced shadow for depth */
}

.recap-info p {
  /* Full width */
  text-align: center;
  /* Center text */
  font-size: 0.8rem;
  /* Standard font size for paragraph */



  /* Reduced margin */
  color: #ffffff;
  /* Bright text for contrast */
}

.recap-info h2 {
  color: rgb(255, 170, 170);
  width: 100%;
  font-size: 1rem;
  /* Larger font size for headings */
  font-weight: 700;
  /* Bold font weight */
  background-color: rgba(255, 255, 255, 0.05);
  /* Very subtle background */
  padding: 3px 0;
  /* Padding for background */
  border-radius: 4px;
  /* Soft edges for background */
}





.recap-info .first-line,
.recap-info .second-line,
.recap-info .third-line {
  display: none;
  /* Initially hidden */
}

.reveal-lines .first-line,
.reveal-lines .second-line,
.reveal-lines .third-line {
  display: flex;
  /* Change from flex to block if they are not flex items */
}

/* Optional: Adjust the max-height on hover to ensure there's enough space for all lines */


.results-counter {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 4px 0;
  /* Dark background for the counter */
  padding: 5px;
  border-radius: 10px;
  /* Rounded corners for the counter background */
}

.results-counter div {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.results-counter .relevant,
.results-counter .not-relevant,
.results-counter .maybe-relevant,
.results-counter .no-access {
  display: flex;
  /* Align labels and numbers on the same line */
  align-items: center;
  /* Center align items vertically */
  gap: 10px;
  /* Space between label and number */
}

.results-counter .relevant .number,
.results-counter .not-relevant .number,
.results-counter .maybe-relevant .number,
.results-counter .no-access .number {
  display: inline-flex;
  /* Use inline-flex for alignment */
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 0 10px 10px 0;
  font-weight: bold;
  transition: transform 0.3s ease;
  /* Space between label and number */

  /* Smooth scaling animation */
}

.results-counter .relevant .number {
  background-color: #4CAF50;
  color: rgb(0, 255, 0);
  /* More vibrant green */
}

.results-counter .not-relevant .number {
  background-color: #F44336;

  color: rgb(255, 196, 196);
  /* More vibrant red */
}

.results-counter .maybe-relevant .number {
  background-color: #f98e0b;
  color: rgb(255, 196, 196);
  /* More vibrant red */
}

.results-counter .no-access .number {
  background-color: #9E9E9E;
  color: rgb(73, 59, 59);
  /* Updated gray for better readability */
}

/* Scaling animation on hover */


.results-counter .label {
  font-size: 0.7em;
  /* Adjust the font size as needed */
  font-weight: bold;
  /* Make the label text bold */
  color: #282727;
  /* Light color for better readability */
  margin: 0;
  /* Remove any default margin */
}

.no-access,
.not-relevant,
.maybe-relevant,
.relevant {
  background-color: rgb(197, 192, 192);
  border-radius: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  /* Space between label and number */
}

@keyframes greenGlow {
  0% {
    box-shadow: 0 0 5px green;
    transform: scale(1);
  }

  50% {
    box-shadow: 0 0 15px green;
    transform: scale(1.05);
  }

  100% {
    box-shadow: 0 0 5px green;
    transform: scale(1);
  }
}

/* Red glow for Not Relevant with scale and border */
@keyframes redGlow {
  0% {
    box-shadow: 0 0 5px red;
    transform: scale(1);
  }

  50% {
    box-shadow: 0 0 15px red;
    transform: scale(1.05);
  }

  100% {
    box-shadow: 0 0 5px red;
    transform: scale(1);
  }
}

/* Gray glow for No Access with scale and border */
@keyframes grayGlow {
  0% {
    box-shadow: 0 0 5px gray;
    transform: scale(1);
  }

  50% {
    box-shadow: 0 0 15px gray;
    transform: scale(1.05);
  }

  100% {
    box-shadow: 0 0 5px gray;
    transform: scale(1);
  }
}

@keyframes orangeGlow {
  0% {
    box-shadow: 0 0 5px orange;
    transform: scale(1);
  }

  50% {
    box-shadow: 0 0 15px orange;
    transform: scale(1.05);
  }

  100% {
    box-shadow: 0 0 5px orange;
    transform: scale(1);
  }
}

/* Apply the animations */
.animate-green-glow {
  animation: greenGlow 2s infinite;
}

.animate-red-glow {
  animation: redGlow 2s infinite;
}

.animate-gray-glow {
  animation: grayGlow 2s infinite;
}

.animate-orange-glow {
  animation: orangeGlow 2s infinite;
}

.separator {
  height: 20px;
  background-color: #2A2A2A;
}

.table-wrapper {
  padding: 0 20px 20px 20px;
  overflow: auto;
  max-height: 65%;
  background-color: #2A2A2A;
  /* Dark background for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Consistent with other shadows */
}

.table-wrapper thead {
  position: sticky;
  top: 0;
  /* Ensure the background is not transparent */
  z-index: 10000;
  /* Ensure the header is above other content when scrolling */
}

.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
  /* For a cleaner look */
  border-spacing: 0;
}


.sourcing-results th {
  background-color: #424242;
  /* Darker shade for a modern look */
  color: #E0E0E0;
  /* Light grey text for contrast */
  padding: 15px;
  /* Comfortable padding */
  text-transform: uppercase;
  /* Maintains formal appearance */
  letter-spacing: 1px;
  /* Slightly increased for readability */
  font-size: 0.95em;
  /* Adjusted for a modern aesthetic */
  border-bottom: 2px solid #333333;
  /* Darker border for subtle contrast */
  font-weight: normal;
  /* Less bold for a sleek look */
}

.sourcing-results td {
  padding: 12px 15px;
  /* Uniform padding */
  background-color: #1E1E1E;
  /* Slightly lighter than table wrapper for contrast */
  color: #CCCCCC;
  /* Lighter text for readability */
  border-bottom: 1px solid #333333;
  /* Subtle separation */
  font-size: 0.9em;
  /* Optimal size for content */
}

.sourcing-results tr {
  transition: background-color 0.3s;
  /* Smooth transition for hover */
}

.sourcing-results tr:hover {
  background-color: #363636;
  /* Dark hover state for interactivity */
}


.sourcing-results th:first-child,
.sourcing-results td:first-child {
  border-top-left-radius: 8px;
  /* Smoothed corners */
  border-bottom-left-radius: 8px;
}

.sourcing-results th:last-child,
.sourcing-results td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.progress-bar-container {
  width: 100%;
  background-color: #1d1a1a;
}

.progress-bar-container .label {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.progress-bar-container .number {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}


.progressBar {
  padding: 10px;
  width: 98% !important;
}

.recap-info .see-settings {
  display: flex;
  font-weight: bold;
  background: linear-gradient(180deg, #424242 0%, #424242 90%, rgb(109, 17, 17) 100%);
  /* Gradient from 424242 to red */
  align-items: center;
  cursor: pointer;
}


/* Hide the "See the sourcing settings" text and arrow on hover */
.recap-info:hover .see-settings {
  display: none;
}

.td-relevant {
  font-weight: bold;
  background-color: #3b673f !important;
  color: #c2fcc7 !important;

  /* Subtle green */
}

.td-maybe-relevant {
  font-weight: bold;
  background-color: #c26c0a !important;
  /* Darker orange for better contrast */
  color: #fff3cc !important;
  /* Softer yellow that's easier to read */
  /* Indicates "maybe" status */
}

.td-not-relevant {
  font-weight: bold;
  background-color: #640513 !important;
  color: #ff5252 !important;
  /* Subtle red */
}

.td-no-access {
  font-weight: bold;
  background-color: #191a1b !important;
  color: #d6c5c5 !important;
  /* Subtle gray */
}

table a {
  color: #f7a9a9;
  text-decoration: none;
  transition: color 0.3s;
}

.sourcing-results tbody tr:hover {
  background-color: #363636;
  /* Dark hover state for interactivity */
  transform: scale(1.025);
  font-weight: bold;
  /* Slightly enlarges the row */
  transition: transform 0.3s ease, background-color 0.3s ease;
  /* Smooth transition for both transform and background color */
  z-index: 1;
  /* Ensures the hovered row is above others */
  position: relative;
  /* Required for z-index to take effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  /* Adds shadow for depth */
  cursor: zoom-in;
  /* Use the magnifying glass cursor */
}

.sourcing-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  /* Adjust the space between buttons */
}

.sourcing-controls button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.sourcing-controls button:hover {
  transform: scale(1);
}

.sourcing-controls button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.pause-stop-button {
  width: 70%;
  /* Adjust width as needed to make it wider */
  background-color: #007bff;
  /* Example color */
  color: white;
  border: none;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  height: 100% !important;

  margin-right: 10px;
  /* Space between buttons */
  cursor: pointer;
}

.cancel-button,
.home-button1,
.home-button2,
.export-button {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;

  background-color: #dc3545;
  /* Example color for cancel */
  color: white;
  border: none;
  padding: 10px;

  height: 100% !important;
  width: 10% !important;
  /* Adjust width as needed */
  cursor: pointer;
}


/* Additional styling for disabled state of export button */
.export-button:disabled {
  background-color: #6c757d;
  /* Example disabled color */
  cursor: not-allowed;
}

.keywords-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  /* Darker overlay for better focus on the modal */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's above everything else */
}

.keywords-overlay-content {
  background-color: #424242;
  /* Matching the panels' background color */
  padding: 20px;
  border-radius: 8px;
  /* Consistent border-radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Consistent shadow with other elements */
  text-align: center;
  color: #e0e0e0;
  /* Light text for readability */
  width: 60%;
  max-height: 80vh;
  overflow-y: auto;
  /* Ensuring it doesn't stretch too wide on larger screens */
}

.keywords-overlay-content h2 {
  margin-top: 0;
  color: #ffffff;
  /* Pure white for headings for better contrast */
  font-weight: 700;
  /* Bold for impact */
}

.keywords-overlay-content ul {
  list-style: none;
  padding: 0;
}

.keywords-overlay-content li {
  margin-bottom: 10px;
  background-color: #333333;
  /* Slightly lighter background for list items */
  padding: 10px;
  border-radius: 4px;
  /* Consistent with other elements */
  /* For layout of list item content */
  color: #e0e0e0;
  /* Light text for readability, similar to input */
  border: 1px solid #757575;
  /* softer border for inputs, applied to li */
}

.keywords-overlay-content button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #6c757d;
  /* Soft gray for subtlety */
  color: #0a0a0a;
  font-weight: bold;
  /* Soft white for elegance and readability */
  border: 1px solid #dee2e6;
  /* Light gray border for a refined look */
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
}


.keywords-overlay-content button:hover {
  background-color: #5a6268;
  /* Slightly darker gray on hover for a subtle effect */
  transform: translateY(-1px);
  /* Gentle lift effect for tactile feedback */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
}

.keywords-overlay-content li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keywords-overlay-content input[type="text"] {
  flex-grow: 1;
  /* Allows the input to grow and fill available space */
}

.keywords-overlay-content button {
  margin-left: 10px;
  /* Adds spacing between buttons and between button and input */
}

.projects-div {
  margin-top: 30px;
  /* Increased space from the header */
  padding: 20px;
  /* Inner spacing */
  /* Light background for emphasis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  border-radius: 8px;
  /* Softened edges */
}

.add-project-button,
.disconnect-button {
  background-color: #891b1b;
  /* Vibrant red */
  font-weight: bold;
  border-radius: 4px;
  /* Smooth corners */
  cursor: pointer;
  /* Indicates interactivity */
  font-size: 1em;
  /* Clear, readable size */
  /* Full width for emphasis */
  margin-bottom: 20px;
  height: 3vw;
  /* Separates from following content */
  transition: background-color 0.3s, transform 0.2s;
  /* Smooth transitions for interaction */
}

.add-project-button {
  width: 100%;
}

.add-project-button:hover,
.disconnect-button:hover {
  background-color: #a52525;
  /* Darker shade on hover */
  transform: translateY(-2px);
  /* Lift effect for tactile feedback */
  box-shadow: 0 0 8px #939090;
  /* Red glow effect */
}

.add-projects {
  display: flex;
  align-items: center;
  justify-content: start;
  /* Aligns items to the start of the flex container */
  gap: 10px;
  /* Adds space between the buttons */
}

.projects-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ongoing-projects,
.completed-projects {
  background-color: #424242;
  /* Dark background for project sections */
  color: #e0e0e0;
  /* Light text color for readability */
  margin-bottom: 20px;
  /* Space between project sections */
  padding: 20px;
  border-radius: 8px;
}

.project-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  /* Space between project items */
}

.project-item span {
  color: #e0e0e0;
  /* Light text color for readability */
  margin-right: 10px;
  /* Space between project details */
}

.projects-div {
  height: 100%;
  margin-bottom: 5%;
  /* Match the height of automated-sourcing-tool */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.sourcing-results th {
  background-color: #424242;
  /* Darker shade for a modern look */
  color: #E0E0E0;
  /* Light grey text for contrast */
  padding: 15px;
  /* Comfortable padding */
  text-transform: uppercase;
  /* Maintains formal appearance */
  letter-spacing: 1px;
  /* Slightly increased for readability */
  font-size: 0.95em;
  /* Adjusted for a modern aesthetic */
  border-bottom: 2px solid #333333;
  /* Darker border for subtle contrast */
  font-weight: normal;
  /* Less bold for a sleek look */
}

.projects-container td {
  padding: 12px 15px;
  /* Uniform padding */
  background-color: #1E1E1E;
  /* Slightly lighter than table wrapper for contrast */
  /* Lighter text for readability */
  border-bottom: 1px solid #333333;
  /* Subtle separation */
  font-size: 0.9em;
  /* Optimal size for content */
}

.projects-container tr {
  transition: background-color 0.3s;
  /* Smooth transition for hover */
}




.projects-container th:first-child,
.projects-container td:first-child {
  border-top-left-radius: 8px;
  /* Smoothed corners */
  border-bottom-left-radius: 8px;
}

.projects-container th:last-child,
.projects-container td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: bold;
}

.projects-container table {
  width: 100%;
}

.projects-container tr:hover {
  /* Darker shade for hover */
  color: rgb(255, 103, 103);
  transform: translateX(10px);
  /* Slightly enlarges the row */
  /* Adds shadow for depth */
  cursor: pointer;
  /* Changes the cursor to a pointer to indicate interactivity */
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition for all properties */
}

/* .projects-table .ongoing-projects table tr td:last-child,
.projects-table .completed-projects table tr td:last-child,
.projects-table .archived-projects table tr td:last-child {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  /* Ensure th cell has a defined height */


/* Ensure the icons are centered if needed */
.projects-table .ongoing-projects table tr td:last-child img,
.projects-table .completed-projects table tr td:last-child img,
.projects-table .archived-projects table tr td:last-child img {
  display: block;
  margin: 0 auto;
  width: 10px;
}


.projects-table .ongoing-projects table tr td:nth-last-child(2),
.projects-table .completed-projects table tr td:nth-last-child(2),
.projects-table .archived-projects table tr td:nth-last-child(2) {

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  /* Smoothed corners on the right side */
  border-bottom-right-radius: 8px;
}

.see-icons,
archive-icons {
  margin-right: 10px;
}

.see-icons:hover,
.archive-icons:hover {
  transform: scale(1.2);
  /* Makes the icon a bit bigger */
  box-shadow: 0 0 8px red;
  /* Adds a red glow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition for scale and glow */
}


@keyframes popEffect {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pop-effect {
  animation: popEffect 0.5s ease-out;
}

.confirm-button-style {
  background-color: #f0ad4e;
  /* Example color */
  color: red;
  animation: blueGlow 2s infinite;

}

@keyframes blueGlow {

  0%,
  100% {
    box-shadow: 0 0 5px rgba(0, 0, 255, 0);
    color: white;

  }

  50% {
    box-shadow: 0 0 20px rgb(255, 0, 30);
    background-color: #301b1c;
    color: #ff5050;
  }
}