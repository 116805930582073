  /* General App styling */
  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
    background-color: #121212;
    /* Dark background for better contrast */
    color: #e0e0e0;
    /* Light text for readability */
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    /* Ensure full width */
    box-sizing: border-box;
    /* Includes padding and border in the element's total width and height */
  }

  .app-container label {
    color: red;
  }

  /* Login container styling */
  .login-container {
    width: 100%;
    max-width: 30vw;
    /* Limits the maximum width */
    padding: 20px;
    background-color: #333333;
    /* Dark grey background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* Subtle shadow for depth */
    text-align: center;
    margin: auto;
    /* Auto margins for horizontal centering */
  }

  /* Form styling */
  .login-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  /* Input fields styling */
  .login-container input[type="text"],
  .login-container input[type="password"] {
    width: calc(100% - 20px);
    /* Adjust width to account for padding */
    padding: 10px;
    border: 1px solid #757575;
    text-align: center;
    /* Softer border for inputs */
    background-color: #424242;
    /* Darker grey for inputs */
    color: #e0e0e0;
    /* Light text for contrast */
    border-radius: 4px;
    transition: border-color 0.3s;
  }

  .login-container input[type="text"],
  .login-container input[type="password"] {
    width: calc(100% - 40px);
    /* Adjust width to account for padding, increased padding */
    padding: 10px;
    border: 1px solid #757575;
    /* Softer border for inputs */
    background-color: #424242;
    /* Darker grey for inputs */
    color: #e0e0e0;
    /* Light text for contrast */
    border-radius: 4px;
    transition: border-color 0.3s;
  }

  /* Label styling */
  .login-container label {
    display: block;
    margin-bottom: 5px;
    color: #e0e0e0;
    /* Light grey for readability */
    text-align: left;
  }

  /* Button styling */
  .login-container button {
    width: 100%;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #e53935;
    /* Red for buttons */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .login-container button:hover {
    background-color: #b71c1c;
    /* Darker red on hover */
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .login-container {
      padding: 10px;
      width: 90%;
    }
  }